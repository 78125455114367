import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Display, WorkBox, Wrapper, WorkBoxText } from "../styles"

const Texts = ({ data }) => {
  const texts = data.allStrapiTexts.edges.map(edge => edge.node)

  return (
    <Layout>
      <SEO title="Texts" />
      <Display center>Texts</Display>

      <Wrapper row wrap justifyEvenly>
        {texts.map(text => (
          <WorkBox
            key={text.title}
            alignCenter
            justifyCenter
            onClick={() => navigate(`/texts/${text.title}/`)}
          >
            <WorkBoxText center>{text.title}</WorkBoxText>
          </WorkBox>
        ))}
      </Wrapper>
    </Layout>
  )
}

export default Texts

export const textsQuery = graphql`
  query {
    allStrapiTexts(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
        }
      }
    }
  }
`
